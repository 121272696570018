import { useState, useEffect } from 'react';
import axios from 'axios';
import blankImage from '../images/blank_image.png'
import SearchableDropdown from './searchableDropdown';
import StatusDialog from './statusDialog';
import CreateStandaloneProduct from './createStandaloneProduct';

const EditProductModal = (props) => {
    const { open, close, product, isVariant, variantImages } = props;
    const [products, setProducts] = useState([]);
    const [name, setName] = useState(null);
    const [description, setDescription] = useState(null);
    const [price, setPrice] = useState(null);
    const [visibility, setVisibility] = useState(null);
    const [salePrice, setSalePrice] = useState(null);
    const [brand, setBrand] = useState(null);
    const [parentProduct, setParentProduct] = useState(null)
    const [mpn, setMpn] = useState(null);
    const [gtin, setGtin] = useState(null);
    const [images, setImages] = useState([]);
    const [size, setSize] = useState(null);
    const [color, setColor] = useState(null);
    const [quantity, setQuantity] = useState(0);
    const [errors, setErrors] = useState([]);
    const [imagePage, setImagePage] = useState(1);
    const [openConfirm, setOpenConfirm] = useState(false);
    const [openStatus, setOpenStatus] = useState(false);
    const [openStandalone, setOpenStandalone] = useState(false);
    const [statusFields, setStatusFields] = useState({});
    const [standalone, setStandalone] = useState(false);
    const [standaloneEditable, setStandaloneEditable] = useState(false);

    const getStandaloneEditable = async (productId) => {
        if (productId) {
            const { data: isEditable } = await axios.get("https://dataeye-be.orienteed.com/standalone-available", { params: { prodId: productId }, headers: { user: JSON.parse(localStorage.getItem("user")).user, token: JSON.parse(localStorage.getItem("user")).token } });

            setStandaloneEditable(isEditable);
        }
    };

    const fetchParentProduct = async () => {
        if (isVariant) {
            if (product?.product_id) {
                const { data: { rows: list } } = await axios.get("https://dataeye-be.orienteed.com/parent-product", { params: { prodId: product.product_id }, headers: { user: JSON.parse(localStorage.getItem("user")).user, token: JSON.parse(localStorage.getItem("user")).token } });

                setParentProduct(list[0]);
            } else {
                setParentProduct(null);
            }
        }
    };

    const getVariantImages = () => {
        let img = [];
        if (isVariant) {
            variantImages.filter((i) => i.variant_id === product.id).map((i) => img.push({ id: i?.attachment_id, url: i?.url }));
        }
        return img.length ? img : [{ id: null, url: "" }];
    };

    const updateVariantImage = (value, pos) => {
        let img = [...images];
        if (isVariant) {
            img[pos] = { id: img[pos]?.id, url: value }
            setImages(img);
        }
    }

    const addVariantImage = () => {
        setImages([...images, { id: null, url: "" }]);
        setImagePage(images.length);
    };

    const handleSubmit = () => {
        let currentErrors = [];
        setErrors([]);

        if (!isVariant && !/^\d+.\d{2}$/.test(price)) {
            currentErrors = [...currentErrors, { id: "price", message: "Please enter a valid price" }];
            setErrors(currentErrors);
        }

        if (!isVariant && !/^\d+.\d{2}$/.test(salePrice)) {
            currentErrors = [...currentErrors, { id: "sale_price", message: "Please enter a valid sale price" }];
            setErrors(currentErrors);
        }

        if (!currentErrors.length) {
            axios.post("https://dataeye-be.orienteed.com/update-product", isVariant ? {
                parent_product: parentProduct,
                is_variant: isVariant,
                product_id: product.id,
                images: images
            } : {
                name: name,
                description: description,
                price: price,
                visibility: visibility,
                sale_price: salePrice,
                brand: brand,
                mpn: mpn,
                gtin: gtin,
                is_variant: isVariant,
                product_id: product.id,
                standalone: standalone
            }, { headers: { user: JSON.parse(localStorage.getItem("user")).user, token: JSON.parse(localStorage.getItem("user")).token } })
                .then((res) => {
                    setStatusFields({ message: res.data.message });
                    setOpenStatus(true);
                })
                .catch((err) => {
                    setStatusFields({ message: err.response.data.message });
                    setOpenStatus(true);
                })
        }
    }

    const handleDelete = () => {
        setOpenConfirm(false);
        axios.post("https://dataeye-be.orienteed.com/delete-product", {
            product_id: product.id
        }, { headers: { user: JSON.parse(localStorage.getItem("user")).user, token: JSON.parse(localStorage.getItem("user")).token } })
            .then((res) => {
                setStatusFields({ message: res.data.message });
                setOpenStatus(true);
            })
            .catch((err) => {
                setStatusFields({ message: err.response.data.message });
                setOpenStatus(true);
            })
    }

    const handleCreatePProduct = (isStandalone) => {
        axios.post("https://dataeye-be.orienteed.com/create-product", {
            name: name,
            description: description,
            price: price,
            sale_price: salePrice,
            visibility: visibility,
            brand: brand,
            mpn: mpn === 'NaN' ? null : mpn,
            gtin: gtin,
            variant_id: product.id,
            variant: product,
            standalone: isStandalone
        }, { headers: { user: JSON.parse(localStorage.getItem("user")).user, token: JSON.parse(localStorage.getItem("user")).token } })
            .then((res) => {
                setStatusFields({ message: res.data.message, product: res.data.product });
                setOpenStatus(true);
            })
            .catch((err) => {
                setStatusFields({ message: err.response.data.message });
                setOpenStatus(true);
            })
    }

    const confirmDialog = (
        <div className="overlay">
            <div className="modal">
                <h3 style={{ textAlign: "center", }}>Delete this product?</h3>
                <button className="ui left floated button" onClick={() => setOpenConfirm(false)}>No</button>
                <button className="ui right floated primary button" onClick={() => handleDelete()}>Yes</button>
            </div>
        </div>)

    useEffect(() => {
        const fetchProducts = async () => {
            if (isVariant) {
                const { data: { rows: list } } = await axios.get("https://dataeye-be.orienteed.com/products", { params: { fromEdit: true }, headers: { user: JSON.parse(localStorage.getItem("user")).user, token: JSON.parse(localStorage.getItem("user")).token } });

                setProducts(list);
            }
        };
        fetchProducts();
    }, []);

    useEffect(() => {
        fetchParentProduct();
        setName(isVariant ? product?.description : product?.name);
        setDescription(product?.description);
        setBrand(product?.brand);
        setPrice(product?.price);
        setSalePrice(product?.sale_price);
        setMpn(product?.mpn);
        setGtin(product?.gtin2);
        setSize(product?.size !== 'NaN' ? product?.size : null);
        setColor(product?.color !== 'NaN' ? product?.color : null);
        setQuantity(product?.quantity);
        setImages(getVariantImages());
        setImagePage(1);
        setVisibility(product?.display_on_website);
        setStandalone(!isVariant ? product?.is_standalone : false);

        if (!isVariant) { getStandaloneEditable(product?.id) }

    }, [product, open]);

    if (!open) return null
    return (
        <div className="overlay">
            <div className="modal">
                <div className="ui form">
                    {isVariant ? <div className="two fields">
                        <div className="field">
                            {images.length > 1 ? <div className="ui right floated pagination menu">
                                <a className="icon item" onClick={() => setImagePage(imagePage === 1 ? images.length : imagePage - 1)}>
                                    <i className="left chevron icon"></i>
                                </a>
                                <a className="icon item" onClick={() => setImagePage(imagePage === images.length ? 1 : imagePage + 1)}>
                                    <i className="right chevron icon"></i>
                                </a>
                            </div> : null}
                            <label>Image
                                <img className="ui large bordered image" src={images[imagePage - 1]?.url !== "" ? images[imagePage - 1]?.url : blankImage}></img>
                                <div className="ui right labeled input">
                                    <input placeholder="Enter image" type="text" value={images[imagePage - 1]?.url || ''} onChange={ev => updateVariantImage(ev.target.value, imagePage - 1)} />
                                    <a className="ui label" onClick={() => addVariantImage()}>
                                        Add other Image
                                    </a>
                                </div>
                            </label>
                        </div>
                        <div className='field'>
                            <div className="field">
                                <label>Name</label>
                                <input type="text" value={name} readOnly />
                            </div>
                            <br />
                            <div className="two fields">
                                <div className="field">
                                    <label>Brand</label>
                                    <input type="text" value={brand} readOnly />
                                </div>
                                <div className="field">
                                    <label>Quantity</label>
                                    <input type="number" value={quantity} readOnly />
                                </div>
                            </div>
                            <div className="two fields">
                                <div className="field">
                                    <label>Sale Price</label>
                                    <input type="number" value={salePrice} readOnly />
                                </div>
                                <div className="field">
                                    <label>Price</label>
                                    <input type="number" value={price} readOnly />
                                </div>
                            </div>
                            <div className="two fields">
                                <div className="field">
                                    <label>MPN</label>
                                    <input type="number" value={mpn} readOnly />
                                </div>
                                <div className="field">
                                    <label>GTIN</label>
                                    <input type="number" value={gtin} readOnly />
                                </div>
                            </div>
                            <div className="two fields">
                                <div className="field">
                                    <label>Size</label>
                                    <input type="text" value={size} readOnly />
                                </div>
                                <div className="field">
                                    <label>Color</label>
                                    <input type="text" value={color} readOnly />
                                </div>
                            </div>
                            <div className="field">
                                <label>Product</label>
                                <SearchableDropdown
                                    options={products}
                                    label="name"
                                    id="id"
                                    selectedVal={parentProduct?.name}
                                    handleChange={(val) => setParentProduct(val)}
                                    parentId={parentProduct?.id}
                                />
                            </div>
                            <br />
                            <div className="inline field">
                                <div className="ui checkbox">
                                    <input type="checkbox" tabIndex="0" defaultChecked={visibility} disabled />
                                    <label>Visible on storefront</label>
                                </div>
                            </div>
                        </div>
                    </div>
                        :
                        <div className='field'>
                            <div className="two fields">
                                <div className="field">
                                    <label>Name</label>
                                    <input placeholder="Name" type="text" value={name} onChange={ev => setName(ev.target.value)} />
                                </div>
                                <div className="field">
                                    <label>Brand</label>
                                    <input placeholder="Brand" type="text" value={brand} onChange={ev => setBrand(ev.target.value)} />
                                </div>
                            </div>
                            <div className="field">
                                <label>Description</label>
                                <input placeholder="Description" type="text" value={description} onChange={ev => setDescription(ev.target.value)} />
                            </div>
                            <div className="two fields">
                                <div className={errors.find((err) => err.id === "sale_price") ? "field error" : "field"}>
                                    <label>Sale Price</label>
                                    <input placeholder="Sale price" type="number" value={salePrice} onChange={ev => setSalePrice(ev.target.value)} />
                                    <label className="errorLabel">{errors.find((err) => err.id === "sale_price")?.message}</label>
                                </div>
                                <div className={errors.find((err) => err.id === "price") ? "field error" : "field"}>
                                    <label>Price</label>
                                    <input placeholder="Price" type="number" value={price} onChange={ev => setPrice(ev.target.value)} />
                                    <label className="errorLabel">{errors.find((err) => err.id === "price")?.message}</label>
                                </div>
                            </div>
                            <div className="two fields">
                                <div className="field">
                                    <label>MPN</label>
                                    <input placeholder="mpn" type="number" value={mpn} onChange={ev => setMpn(ev.target.value)} />
                                </div>
                                <div className="field">
                                    <label>GTIN2</label>
                                    <input placeholder="gtin2" type="number" value={gtin} onChange={ev => setGtin(ev.target.value)} />
                                </div>
                            </div>
                            <div className="inline field">
                                <div className="ui checkbox">
                                    <input type="checkbox" tabIndex="0" defaultChecked={visibility} onChange={ev => setVisibility(ev.target.checked)} />
                                    <label>Visible on storefront</label>
                                </div>
                                <div className="ui checkbox" style={{ marginLeft: '20px' }}>
                                    {standaloneEditable ?
                                        <input type="checkbox" tabIndex="0" defaultChecked={standalone} onChange={ev => setStandalone(ev.target.checked)} />
                                        :
                                        <input type="checkbox" tabIndex="0" defaultChecked={standalone} disabled />
                                    }
                                    <label>Standalone product</label>
                                </div>
                            </div>
                        </div>}
                    <div>
                        {/* {!isVariant && <button className="negative ui left floated button" onClick={() => setOpenConfirm(true)}>Delete</button>} */}
                        {isVariant && (parentProduct?.id === 0 || !parentProduct) && <button className="ui positive left floated button" onClick={() => setOpenStandalone(true)}>Create product</button>}
                        <button className="ui right floated button" onClick={close}>Cancel</button>
                        <button className="ui right floated primary button" onClick={() => handleSubmit()}>Save</button>
                    </div>
                </div>
                {openStandalone && <CreateStandaloneProduct setStandalone={setStandalone} handleCreatePProduct={handleCreatePProduct} close={() => setOpenStandalone(false)} />}
                {/* {openConfirm ? confirmDialog : null} */}
                {openStatus && <StatusDialog message={statusFields?.message} close={() => { setOpenStatus(false); close() }} redirect={isVariant ? "/variants" : "/products"} product={statusFields.product} />}
            </div>
        </div>
    )
}

export default EditProductModal;