import { useState, useEffect, useCallback } from 'react';
import { useNavigate } from "react-router-dom";
import axios from 'axios';
import EditProductModal from './editProductModal';
import Header from './header';
import redCircle from '../images/red-circle.png';
import yellowCircle from '../images/yellow-circle.png';
import greenCircle from '../images/green-circle.png';

const Variants = props => {
    const { loggedIn, setLoggedIn } = props;
    const [productFetch, setProductFetch] = useState([]);
    const [variantImages, setVariantImages] = useState([]);
    const [variantList, setVariantList] = useState([]);
    const [variantListView, setVariantListView] = useState(null);
    const [variant, setVariant] = useState(null);
    const [editIsOpen, setEditIsOpen] = useState(false);
    const [totalPages, setTotalPages] = useState(1);
    const [currentPage, setCurrentPage] = useState(1);
    const navigate = useNavigate();

    const fetchVariants = async () => {
        let result = [];
        let fileredList = [];
        const { data: { rows: list, attachments: images } } = await axios.get("https://dataeye-be.orienteed.com/variants", { headers: { user: JSON.parse(localStorage.getItem("user")).user, token: JSON.parse(localStorage.getItem("user")).token } });
        fileredList = list.filter(x => x);

        setTotalPages(Math.ceil(fileredList.length / 25))

        setProductFetch(fileredList);
        setVariantImages(images);
        setVariantList(fileredList);

        fileredList.slice(0, 25).map((prod, index) => {
            result.push(
                <tr onClick={() => { setEditIsOpen(true); setVariant(prod) }}>
                    <td>{index + 1}</td>
                    <td>{prod.sku}</td>
                    <td>{prod.description}</td>
                    {prod.size === 'NaN' && prod.color === 'NaN' ? <td className="negative">{null}</td> : <td>{prod.size === 'NaN' ? null : prod.size}</td>}
                    {prod.size === 'NaN' && prod.color === 'NaN' ? <td className="negative">{null}</td> : <td>{prod.color === 'NaN' ? null : prod.color}</td>}
                    {prod.product_id === 0 ? <td className="negative">NO PRODUCT</td> : <td>{prod.product_id}</td>}
                    <td>{prod.display_on_website ? 'Yes' : 'No'}</td>
                    <td>{prod.price}</td>
                    <td>{prod.sale_price}</td>
                    {prod.flag === 0 ? <td className="negative"><img src={redCircle} width="25px" /></td> : prod.flag === 1 ? <td className="warning"><img src={yellowCircle} width="25px" /></td> : <td className="positive"><img src={greenCircle} width="25px" /></td>}
                </tr>
            )
        })

        setVariantListView(result);
    }

    const getVariantList = (page) => {
        let result = [];

        if (page > 0 && page <= totalPages && variantList.length) {
            variantList.slice((page - 1) * 25, page * 25).map((prod, index) => {
                result.push(
                    <tr onClick={() => { setEditIsOpen(true); setVariant(prod) }}>
                        <td>{index + 1}</td>
                        <td>{prod.sku}</td>
                        <td>{prod.description}</td>
                        {prod.size === 'NaN' && prod.color === 'NaN' ? <td className="negative">{null}</td> : <td>{prod.size === 'NaN' ? null : prod.size}</td>}
                        {prod.size === 'NaN' && prod.color === 'NaN' ? <td className="negative">{null}</td> : <td>{prod.color === 'NaN' ? null : prod.color}</td>}
                        {prod.product_id === 0 ? <td className="negative">NO PRODUCT</td> : <td>{prod.product_id}</td>}
                        <td>{prod.display_on_website ? 'Yes' : 'No'}</td>
                        <td>{prod.price}</td>
                        <td>{prod.sale_price}</td>
                        {prod.flag === 0 ? <td className="negative"><img src={redCircle} width="25px" /></td> : prod.flag === 1 ? <td className="warning"><img src={yellowCircle} width="25px" /></td> : <td className="positive"><img src={greenCircle} width="25px" /></td>}
                    </tr>
                )
            });

            setCurrentPage(page);

            setVariantListView(result);
        } else {
            setVariantListView(<tr><td colSpan="8"><b>There are no products</b></td></tr>)
        }
    }

    const getPages = useCallback(() => {
        let result = [];

        if (totalPages > 5 && currentPage >= 4 && currentPage <= totalPages - 3 && totalPages !== 0) {
            result.push(
                <a className="item" onClick={() => getVariantList(1)}>1</a>,
                <a className="item" onClick={() => getVariantList(2)}>2</a>,
                <a className="item">...</a>,
                <a className="item" onClick={() => getVariantList(currentPage - 1)}>{currentPage - 1}</a>,
                <a className="item active" onClick={() => getVariantList(currentPage)}>{currentPage}</a>,
                <a className="item" onClick={() => getVariantList(currentPage + 1)}>{currentPage + 1}</a>,
                <a className="item">...</a>,
                <a className="item" onClick={() => getVariantList(totalPages - 1)}>{totalPages - 1}</a>,
                <a className="item" onClick={() => getVariantList(totalPages)}>{totalPages}</a>
            )
        } else if ((currentPage <= 4 || currentPage >= totalPages - 3) && totalPages >= 7 && totalPages !== 0) {
            result.push(<a className={currentPage === 1 ? "item active" : "item"} onClick={() => getVariantList(1)}>1</a>,
                <a className={currentPage === 2 ? "item active" : "item"} onClick={() => getVariantList(2)}>2</a>,
                <a className={currentPage === 3 ? "item active" : "item"} onClick={() => getVariantList(3)}>3</a>,
                <a className={currentPage === 4 ? "item active" : "item"} onClick={() => getVariantList(4)}>4</a>,
                <a className="item">...</a>,
                <a className={currentPage === totalPages - 3 ? "item active" : "item"} onClick={() => getVariantList(totalPages - 3)}>{totalPages - 3}</a>,
                <a className={currentPage === totalPages - 2 ? "item active" : "item"} onClick={() => getVariantList(totalPages - 2)}>{totalPages - 2}</a>,
                <a className={currentPage === totalPages - 1 ? "item active" : "item"} onClick={() => getVariantList(totalPages - 1)}>{totalPages - 1}</a>,
                <a className={currentPage === totalPages ? "item active" : "item"} onClick={() => getVariantList(totalPages)}>{totalPages}</a>
            )
        } else {
            for (let i = 0; i < totalPages; i++) {
                result.push(<a className={currentPage === i + 1 ? "item active" : "item"} onClick={() => getVariantList(i + 1)}>{i + 1}</a>)
            }
        }

        return result;
    }, [totalPages, currentPage]);

    useEffect(() => {
        if (loggedIn) fetchVariants();
    }, [loggedIn])

    useEffect(() => {
        if (productFetch.length && loggedIn) {
            getVariantList(1);
        }
    }, [variantList, loggedIn])

    if (!loggedIn) {
        return (
            <div className="mainContainer">
                <div className={"titleContainer"}>
                    <div>You must be logged in</div>
                </div>
            </div>
        )
    }

    return (
        // <div className={"mainContainer"}>
        <div>
            <Header productFetch={productFetch} setProductList={setVariantList} setTotalPages={setTotalPages} getProductList={getVariantList} setLoggedIn={setLoggedIn} isVariant={true} />
            <div className="ui top attached tabular menu">
                <a className="item" data-tab="products" onClick={() => navigate('/products')}>Products</a>
                <a className="item active" data-tab="variants">Variants</a>
            </div>
            <EditProductModal open={editIsOpen} close={() => setEditIsOpen(false)} product={variant} variantImages={variantImages} isVariant={true} />
            <table className="ui selectable celled table">
                <thead>
                    <tr>
                        <th></th>
                        <th>SKU</th>
                        <th>Name</th>
                        <th>Size</th>
                        <th>Color</th>
                        <th>Parent product</th>
                        <th>Display on website</th>
                        <th>Price</th>
                        <th>Sale price</th>
                        <th>Status</th>
                    </tr>
                </thead>
                <tbody>
                    {variantListView}
                </tbody>
                <tfoot>
                    {productFetch.length !== 0 ? <tr><th colSpan="10">
                        <div className="ui right floated pagination menu">
                            {totalPages > 1 ? <a className="icon item" onClick={() => getVariantList(currentPage - 1)}>
                                <i className="left chevron icon"></i>
                            </a> : null}
                            {getPages()}
                            {totalPages > 1 ? <a className="icon item" onClick={() => getVariantList(currentPage + 1)}>
                                <i className="right chevron icon"></i>
                            </a> : null}
                        </div>
                    </th></tr> : null}
                </tfoot>
            </table>

        </div>
    )
}

export default Variants;